<template>
  <div class="search-header">
    <a-form layout="inline"
            :model="searchForm">
      <a-form-item>
        <a-input-search v-model:value="searchForm.name"
                        placeholder="请输入观众昵称"
                        style="width: 360px"
                        @change="onSearch" />
      </a-form-item>
    </a-form>

    <a-button type="primary"
              style="width: 146px;height: 36px"
              :loading="lodash.includes(['pending', 'downloading'], process.processStatus)"
              :disabled="viewerList.length === 0"
              @click="handleExport">
      {{ exprotStatus }}
    </a-button>
  </div>

  <a-table :columns="viewerColumns"
           :data-source="viewerList"
           row-key="id"
           :pagination="pagination"
           :scroll="{ y: '100vh' }"
           :loading="tableLoading"
           bordered
           @change="onPaginationChange">
    <template #memberInfo="{ record }">
      <avatar-column :avatar="{ avatar: record.memberInfo.memberAvatar, name: record.memberInfo.memberName }" />
    </template>

    <template #watchDuration="{ record }">
      {{ getDuration(record.memberInfo.watchDuration) }}
    </template>

    <template #action="{ record }">
      <template v-if="record.targetSource === 'guest'">
        <router-link
                     :to="{path:'/customer/customerDetail',query:{ customerId: record.memberInfo.customerId, id: record.memberInfo.customerRelationId}}">
          <a-button type="link"
                    :disabled="!record.memberInfo.customerRelationId">客户详情</a-button>
        </router-link>
      </template>

      <template v-else-if="record.targetSource === 'staff'">
        <router-link :to="{path:'/enterprise/memberDetail',query:{ id: record.memberInfo.staffId}}">

          <a-button type="link">员工详情</a-button>
        </router-link>
      </template>

    </template>
  </a-table>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import liveApi from "@/service/api/live";
import { timestampToHour } from "@/global";

import AvatarColumn from "@/components/AvatarColumn";

import { apiStore } from "@/shared/service/api-client";

export default defineComponent({
  name: "LiveStatisticViewData",

  components: {
    AvatarColumn,
  },

  setup() {
    const route = new useRoute();
    const router = new useRouter();

    const { id: roomId } = route.query;

    const getDuration = function (text) {
      const time = text;
      const timeFormat = timestampToHour(time);

      return `${Number(timeFormat.hours) ? `${timeFormat.hours}时` : ""}${
        Number(timeFormat.minutes) ? `${timeFormat.minutes}分` : ""
      }${timeFormat.seconds}秒`;
    };

    // 观众数据表格
    const viewerColumns = [
      {
        title: "观众",
        dataIndex: "memberInfo",
        slots: { customRender: "memberInfo" },
      },
      {
        title: "观看时长",
        dataIndex: "memberInfo",
        slots: { customRender: "watchDuration" },
      },
      {
        title: "评论数",
        dataIndex: "chatNum",
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
      },
    ];

    const searchForm = reactive({
      roomId,
      name: "",
    });

    const tableLoading = ref(false);
    const rows = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 20,
      total: 0,
      pageSizeOptions: ["10", "20", "50"],
      showSizeChanger: true,
      showTotal: (total) => `共 ${total} 条`,
      showQuickJumper: true,
    });

    const onSearchSubmit = async () => {
      pagination.current = 1;
      const query = _.assign({}, searchForm, {
        page: _.toString(pagination.current),
        size: _.toString(pagination.pageSize),
      });

      if (_.isEqual(route.query, query)) {
        await fetchPaginationData();
      } else {
        await router.push({ name: route.name, query: query });
      }
    };

    const pullQueryParams = (query) => {
      if (query.page) {
        pagination.current = _.toInteger(query.page);
      }

      if (query.size) {
        pagination.pageSize = _.toInteger(query.size);
      }
    };

    const fetchPaginationData = async () => {
      tableLoading.value = true;

      const response = await liveApi.searchStudent({
        ...searchForm,
        page: pagination.current - 1,
        size: pagination.pageSize,
      });

      rows.value = response.data;
      pagination.total = response.total;
      tableLoading.value = false;
    };

    const onPaginationChange = async (pagination, filters, sorter) => {
      let sortParams = {};
      if (sorter.field && sorter.order) {
        sortParams = {
          sort:
            sorter.field + "," + (sorter.order === "ascend" ? "asc" : "desc"),
        };
      } else {
        sortParams = { sort: "" };
      }

      const query = _.assign(
        {},
        route.query,
        { page: pagination.current, size: pagination.pageSize },
        sortParams
      );
      pullQueryParams(query);
      const response = await liveApi.searchStudent({
        ...searchForm,
        page: pagination.current - 1,
        size: pagination.pageSize,
      });
      rows.value = response.data;
      pagination.total = response.total;
      router.push({ name: route.name, query: query });
    };

    const onSearch = _.debounce(() => {
      fetchPaginationData();
    }, 500);

    // 导出观众数据
    const exprotStatus = ref("导出");

    const process = reactive({
      processStatus: "unstart",
    });

    const handleExport = () => {
      if (_.includes(["unstart", "downloaded"], process.processStatus)) {
        exportedViewerData();
        return;
      }

      if (process.processStatus === "exported") {
        downloadViewrData();
      }
    };

    const exportedViewerData = async () => {
      await liveApi.asyncExport({
        roomId,
        name: searchForm.name,
      });
      exprotStatus.value = "导出中..";
      checkProcessStatus();
    };

    const timer = ref();
    const checkProcessStatus = () => {
      let i = 0;
      timer.value = setInterval(async () => {
        i++;
        if (i >= 60) {
          clearInterval(timer.value);
        }
        const res = await liveApi.getLastExportStatus();
        _.assign(process, res);

        if (process.processStatus === "exported") {
          message.success("导出成功");
          exprotStatus.value = "下载";

          clearInterval(timer.value);
        }

        if (process.processStatus === "downloaded") {
          message.success("下载成功");
          exprotStatus.value = "导出";

          clearInterval(timer.value);
        }
      }, 500);
    };

    const downloadViewrData = async () => {
      let url =
        "https://" +
        document.domain +
        "/api/console/live/room/download?id=" +
        process.id +
        "&token=" +
        apiStore.token;

      window.location.href = url;
      checkProcessStatus();
    };

    onMounted(async () => {
      pullQueryParams(route.query);
      await fetchPaginationData();
    });

    return {
      lodash: _,
      viewerColumns,
      searchForm,

      getDuration,

      viewerList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
      onSearch,

      exprotStatus,
      process,
      timer,
      handleExport,
    };
  },
});
</script>